<template>
  <div class="invite" v-loading="loading">
    <div class="logo">
      <img src="@/assets/logo.png" alt="" />
    </div>
    <div class="title" v-if="!complete">账号注册</div>
    <el-form class="form" :model="entity" ref="form" @submit.native.prevent v-if="!complete">
      <el-form-item v-if="showPhone" prop="phone">
        <el-input prefix-icon="el-icon-mobile-phone" type="number" v-model="entity.phone" placeholder="请输入手机号"
          autocomplete="off"></el-input>
      </el-form-item>
      <div v-else>
        <el-form-item prop="pass">
          <el-input type="number" v-model="entity.code" placeholder="请输入验证码" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="pass">
          <el-input v-model="entity.pass" placeholder="请输入密码" show-password></el-input>
        </el-form-item>
        <el-form-item prop="checkPass">
          <el-input v-model="entity.checkPass" placeholder="请再次输入密码" show-password></el-input>
        </el-form-item>
      </div>
      <el-form-item>
        <el-button size="mini" v-if="showPhone" type="primary" @click="sendCode()" style="width: 100%">发送验证码</el-button>
        <el-button size="mini" v-else type="primary" @click="submitForm('form')" style="width: 100%">注 册</el-button>
      </el-form-item>
    </el-form>
    <el-result v-else icon="success" title="成功提示" subTitle="注册成功">
      <template slot="extra">
        <el-button type="primary" @click="close" style="padding:10px 0">确定</el-button>
      </template>
    </el-result>
  </div>
</template>

<script>
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.entity.checkPass !== "") {
          this.$refs.form.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value != "" && value != this.entity.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var checkPhone = (rule, value, callback) => {
      let reg = /^1[345789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入正确格式的手机号"));
      } else {
        callback();
      }
    };
    return {
      showPhone: true,
      entity: {},
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
        phone: [{ validator: checkPhone, trigger: "blur" }],
        phone: [{ require: true, message: "请输入验证码", trigger: "blur" }],
      },
      loading: false,
      complete: false,
    };
  },
  mounted() { },
  methods: {
    close() {
      this.$router.replace('/XunJianGo')
    },
    sendCode() {
      this.$refs.form.validateField("phone", async (valid) => {
        if (valid) return;
        this.loading = true;
        let result = await this.$http.post(
          "/Base_Manage/Home/ValidateVIPUserPhone?phone=" + this.entity.phone
        );
        console.log(result);
        if (result.Success && !result.Data) {
          this.loading = false;
          return this.$message.error("当前手机号已完成注册");
        }
        this.$http.post('/Base_Manage/Base_SMSConfig/GetTemplateCodeByCode?code=' + '').then(codeData => {
          if (codeData.Success) {
            this.$http
              .post(
                "/Base_Manage/Home/GetShortMessage?Phone=" +
                this.entity.phone +
                "&TemplateCode=" + codeData.Data
              )
              .then((res) => {
                this.loading = false;
                if (res.Success) {
                  this.showPhone = false;
                  this.codeData = JSON.parse(res.Data);
                  this.$message.success("验证码已经发送至您的手机,请注意查收");
                } else {
                  this.$message.error(res.Msg);
                }
              });
          } else {
            this.$message.error(codeData.Msg);
          }
        })

      });
    },
    submitForm(formName) {
      console.log('获取表单', this.entity)
      if (!this.entity.code) return this.$message.error('请输入验证码')
      if (!this.entity.pass) return this.$message.error('请输入密码')
      if (!this.entity.checkPass) return this.$message.error('请再次输入密码')
      if (this.entity.pass !== this.entity.checkPass) return this.$message.error('两次密码输入不一致')
      this.$http
        .post("/Base_Manage/Home/AddVIPUserInfoDTOData", {
          Phone: this.entity.phone,
          Password: this.entity.pass,
          UserId: this.$route.query.UserId,
          SmsCode: this.entity.code,
        })
        .then((res) => {
          if (res.Success) this.complete = true;
          else this.$message.error(res.Msg);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  box-sizing: border-box;
}

.invite {
  width: 100%;
  height: 100%;

  ::v-deep .el-button--mini {
    padding: 12px 15px;
  }

  .logo {
    width: 100%;
    padding: 20px 40px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .title {
    padding: 20px;
    font-weight: bold;
    font-size: 20px;
  }

  .form {
    padding: 20px;
  }
}
</style>